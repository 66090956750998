body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.tabContent {
  display: block;
  margin-bottom: 10px;
}
main {
  padding-top: 10px
}